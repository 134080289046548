div.crm-container .crm-form-radio + label {
  @include clearfix;
  font-size: 1em;
  &:after {
    @include clearfix;
    margin-bottom: 25px;
  }
  &:last-child:after {
    margin-bottom: 0;
  }
}

.crm-event-register-form-block {
  .crm-profile-name-Conf_reg_preferences_16 {
    input[type="checkbox"], 
    input[type="radio"] {
      vertical-align: top;
      margin-top: 5px;
    }
    .form-item label {
      width: calc(100% - 30px);
      clear: left;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}