// // MENU FLASH FIX
// // --- Set height for menu here to ensure menu loading causes minimal fuss
// #site-header {
// 	.block-menu-block {
// 		display: none;
// 	}
// 	.main-nav-wrapper,
// 	.block-menu-block {
		
// //		@media (min-width:880px) and (max-width:1150px) {
// //			min-height:48px;
// //		}
// //
// //		@include media(1150px) {
// //			min-height:48px;
// //		}
// 	}
// }

// MENU STYLES
#site-header {
	@include media(720px) {
		.main-nav-wrapper {
			background: $nav-wrapper-bg-color;
			border-bottom: 4px solid $sapphire_blue;
			// position: relative;
			// clear: both;
		}
	}
	.block-menu-block {
		// clear:right;
		// position: relative;
		@include media(970px) {
			float: right;
		}
		
		// @media screen and (min-width: 880px) {
		// 	margin-right: 0;
		// }
		
		.main-menu-btn {
			// float:right;
			// width:$nav-button-width;
			// height:$nav-button-height;
			// text-indent:-999em;
			// margin: -80px 0px 0px 0px;
			
			// @media (max-width:719px) {
			// 	height:60px;
			// 	margin: -60px 0px 0px 0px;
			// 	width:60px;
			// 	height:60px;
			// }
						
			.main-menu-btn-icon {
 			// 	left:20px;
				// height:$nav-button-bar-height;
				// width:$nav-button-bar-width;
				// background-color:$nav-button-bar-bg-color;
				// border-radius:$nav-button-bar-border-radius;
				// margin-top:-2px;
				// @media (max-width:719px) {
				// left:12px;
				// }
				
				&:before {
					// top:-14px;
					// left:0;
					// height:$nav-button-bar-height;
					// width:$nav-button-bar-width;
					// background-color:$nav-button-bar-bg-color;
					// border-radius:$nav-button-bar-border-radius;
				}
				
				&:after {
					// top:14px;
					// left:0;
					// height:$nav-button-bar-height;
					// width:$nav-button-bar-width;
					// background-color:$nav-button-bar-bg-color;
					// border-radius:$nav-button-bar-border-radius;
				}
			} 
		}
		
		ul.sm {
			// background: none; 
			// clear:both;
		}
		
		@include media(880px) { 
			// clear:right;
			//float:right;
		}		
		
		.menu-block-wrapper ul.menu.sm  {
			
			// border:none;
			// box-shadow:none;
					
			& > li {
				// border-left:none;
				// flex-grow: 1;
				// text-align: center;

				&:last-child { 
					// position:relative;
				}

				@include media(880px) { 
					& > a > .sub-arrow {
						// display:none;
					}
				}
				
				&.menu-mlid-3251,
				&.menu-mlid-3252,
				&.menu-mlid-3253, {
					a {
					color:$true_blue;
						&:active {
							// background-color:$nav-active-bg-color;
						}
					}
				}

				& > a {
					// background-color: $nav-base-bg-color;
					// text-align: center;
					font:$nav-base-font;
					font-weight:normal;
					color:$nav-base-text-color;
					// padding:$nav-base-padding;
					// //text-transform: uppercase;

					// @media (min-width:880px) and (max-width:1150px) {
					// 	font: $nav-base-font-smaller;
					// 	padding: $nav-base-padding-smaller;
					// }

					&:hover {
						// border-bottom: $nav-hover-border;
						// padding:$nav-base-hover-padding;
						// background-color:$nav-hover-bg-color;
						color:$nav-hover-text-color;

						// @media (min-width:880px) and (max-width:1150px) {
						// 	padding:$nav-base-hover-padding-smaller;
						// }
					}			
					&.current,
					&.active.current.highlighted {
						background-color:$nav-active-bg-color;
						// color:$nav-active-text-color;
						// border-radius:$nav-active-border-radius;
            &:hover {
              background-color:$nav-sub-hover-bg-color
            }
					}
          
          &.current {
            &:hover {
              background-color:$nav-sub-hover-bg-color
            }
          }

					&.current.highlighted {
						background-color:$true_blue;
						color: white;
						// border-radius:$nav-active-border-radius;
					}

					&.highlighted {
						// background-color:white;
						// color:$nav-sub-text-color;
						// border-bottom: $nav-highlight-border;
						// border-top: none;
						// padding:$nav-base-hover-padding;

						// @media (min-width:880px) and (max-width:1150px) {
						// 	padding:$nav-base-hover-padding-smaller;
						// }

						&:hover {
						  background-color:$nav-sub-hover-bg-color;
							// color:$nav-hover-text-color;
						}
					}
				}
			}
			
			li {
				a {
					// cursor:pointer; 
					// background-color: transparent;
										
					.sub-arrow {
						// background:$arrow-red-right no-repeat scroll center center transparent;
						// background-size:contain;
						// width: 16px;
						// text-indent:-999em;
						// overflow:hidden;
						// right:0;
					}
				}
				
				ul.menu {	
					// background:$nav-sub-bg-color;
					// border:$nav-sub-border;
					// border-radius:$nav-sub-border-radius;
					// box-shadow:$nav-sub-box-shadow;
					
					li {
						// border-top:none; 
						
						a {
							// color:$nav-sub-text-color;
							// font: $nav-sub-base-font;
							// line-height:1.5;
							// padding:$nav-sub-base-padding;
							
							&.current {
								// background:$nav-sub-bg-color;
							}
							
							&:hover {
								// background-color:$nav-sub-hover-bg-color;
								// color:$nav-sub-hover-text-color;
							}  
							&.active {
								background-color:$nav-active-bg-color;
								// color:$nav-sub-hover-text-color;
                &:hover {
                  background-color:$nav-sub-hover-bg-color;
                }
							} 
							
							.sub-arrow {
								// background:$arrow-red-right no-repeat scroll center center transparent;
								// background-size:16px 29px;
								// text-indent:-999em;
								// overflow:hidden;
								// right:10px;
								// top:17px;
								// height:29px;
							}	
							
							&.active {
								.sub-arrow {
									// background:$arrow-white-right no-repeat scroll center center transparent;
									// background-size:16px 29px;
									// top:17px;
									// height:29px;
								}
							}
							
							&:hover, 
							&.active:hover  {
								.sub-arrow {
									// background:$arrow-white-right no-repeat scroll center center transparent;
									// background-size:16px 29px;
									// top:17px;
									// height:29px;
								}								
							}							
						}						
						&.expanded {
							
							& > a.highlighted { 
								// background-color:$nav-expanded-bg-color;
								// color:$nav-expanded-text-color;

								.sub-arrow {
									// background:$arrow-red-right no-repeat scroll center center transparent;
									// background-size:16px 29px;
									// top:17px;
									// height:29px;
								}
								

								&:hover {
									// background-color:$nav-sub-hover-bg-color;
									// color:$nav-sub-hover-text-color;
									.sub-arrow {
										// background:$arrow-white-right no-repeat scroll center center transparent;
										// background-size:16px 29px;
										// top:17px;
										// height:29px;
									}
								}
								&.current.active {
									// background-color:$nav-active-bg-color;
									// color:$nav-sub-hover-text-color;
								}
							}
						}
						
						ul.menu {
							// @media (min-width:720px) {
							// 	border-left:solid 2px $sunset_orange;
							// }
							li a {
								&:hover {
									// background-color:$sunset_orange;
									// color:white;
								}
								
								&.active {
									// background-color:$true_blue;
									// color:white;
								} 
							}
						}
					}
				}
			}	
		}	
		
////////////////////////////////////      MOBILE      ////////////////////////////////////
		@media (max-width:879px) {
		
			// #main-menu-state:checked ~ .main-menu-btn { background:$nav-button-hover-bg-color;} //margin: 0 0 1px; height: 75px;}
			// #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:$nav-button-bar-height; margin-top: -4px; background-color:$nav-button-bar-hover-bg-color; }
			// #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
			// #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-button-bar-hover-bg-color; }
			// #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
			// #main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }


			.menu-block-wrapper ul.menu.sm {
				
				//border: $nav-mob-border;
				border-top: 3px solid $sapphire_blue; 
				// box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
				
				// position: absolute;
				// right: 0;
				// width: calc(100% - 5%); 
				// max-width: 400px;
				// z-index: 1005;
				
				// @media (min-width:$narrow) and (max-width:879px) {
				// 	top: 0;
				// }
				
				& > li {
					
					&.first.leaf {
						//border: none;
						//border-top:$nav-mob-expanded-border;
					}
					&.first.first {
						// border: none;
					}
					
					a {
						// font:$nav-sub-base-font !important;
						.sub-arrow {
							// width: 34px;
						}
					}

					///////////////////////////////      1st LEVEL      ///////////////////////////////
					& > a {
						// background-color:$nav-mob-1st-bg-color;
						// text-align: left;
						// font:$nav-base-font;
						// color:$nav-sub-text-color;
						// padding:$nav-sub-base-padding;
						//text-transform: uppercase;

						&:hover {
							// background-color:$nav-sub-hover-bg-color;
							// color:$nav-sub-hover-text-color;
							.sub-arrow {
								// background:$arrow-white-down no-repeat scroll left center transparent;
								// background-size:70%;
							}	
						}

						&.current { 
							// background:$nav-mob-1st-highlight-bg-color;
							// color:$nav-mob-1st-highlight-text-color;
							//border-bottom: $nav-mob-border;
							// padding:$nav-sub-base-hover-padding;
							//border-top:$nav-mob-expanded-border;
							.sub-arrow {
								// background:$arrow-white-down no-repeat scroll left center transparent;
								// background-size:70%;
							}
							&:hover {						
								// background-color:$nav-sub-hover-bg-color;
								// color:$nav-hover-text-color;
								.sub-arrow {
									// background:$arrow-white-down no-repeat scroll left center transparent;
									// background-size:70%;
								}
							}
						}

						&.active { 
							// background-color:$nav-mob-active-bg-color;
							// color:$nav-active-text-color;
							.sub-arrow {
								// background:$arrow-white-down no-repeat scroll left center transparent;
								// background-size:70%;
							}
							&:hover {
								.sub-arrow {
									// background:$arrow-white-down no-repeat scroll left center transparent;
									// background-size:70%;
								} 
							}
						}

						&.highlighted {
							// background-color:$nav-mob-2nd-highlight-bg-color;
							// color:$nav-mob-2nd-highlight-text-color;
							//border-bottom: $nav-hover-border;
							// padding:$nav-sub-base-hover-padding;
							.sub-arrow {
								// background:$arrow-red-up no-repeat scroll left center transparent;
								// background-size:70%;						
							}
							&:hover {
								background-color:$nav-mob-active-bg-color;
								// color:$nav-hover-text-color;
								.sub-arrow {
									// background:$arrow-white-up no-repeat scroll left center transparent;
									// background-size:70%;
								}	
							}
						}

						&.active-trail {
							background-color:$nav-mob-active-bg-color;
							// color:$nav-mob-1st-highlight-text-color;
							//border-bottom: $nav-highlight-border;
							// padding:$nav-sub-base-hover-padding;

							&:hover {
								// background-color:$nav-sub-hover-bg-color;
								// color:$nav-hover-text-color;
							}
						}
						&.current.highlighted {
							// background-color:$nav-mob-2nd-highlight-bg-color;
							// color:$nav-mob-2nd-highlight-text-color;
							//border-bottom: $nav-highlight-border;
							// padding:$nav-sub-base-hover-padding;

							&:hover {
								// background-color:$nav-sub-hover-bg-color;
								// color:$nav-hover-text-color;
							}
						}

						&.active.highlighted {
							.sub-arrow {
								// background:$arrow-white-up no-repeat scroll left center transparent;
								// background-size:70%;
							}
							&:hover {
								.sub-arrow {
									// background:$arrow-white-up no-repeat scroll left center transparent;
									// background-size:70%;
								}
							}
						} 
					}
				}
				
				li {
					//border-top: $nav-mob-expanded-border;
					
					a {
						.sub-arrow {
							// background:$arrow-grey-down no-repeat scroll left center transparent;
							// background-size:70%;
							// height: 46px;
							// margin-top: -23px;
							//border-left: $nav-mob-border;
						}
					}
					
					
					///////////////////////////////      NEXT LEVELS      ///////////////////////////////
					
					/* ul BACKGROUNDS */
					&.expanded {
						ul.menu,
						ul.menu a.current {
							// background-color: $nav-mob-2nd-bg-color;
							//border-top:$nav-mob-expanded-borde
							//border-bottom:$nav-mob-expanded-border;
							
							&.has-submenu:hover {
								// background-color:$nav-sub-hover-bg-color;
							}
							a.active {
								background-color: $nav-active-bg-color;
							}

							li.expanded {
								ul.menu {	
									// background-color: $nav-mob-3rd-bg-color;
									//border-top:$nav-mob-expanded-border;
									// border-bottom:$nav-mob-expanded-border;

									li:not(.first) {
										//border-top:$nav-mob-expanded-border;
									}

									a.active {
										// background-color: $nav-mob-3rd-bg-color;
										// color: $action-hover;
									}
								}

								li.expanded {
									ul.menu {	
										// background-color: $nav-mob-4rd-bg-color;
										//border-top:$nav-mob-expanded-border;
										//border-bottom:$nav-mob-expanded-border;

										li:not(.first) {
											//border-top:$nav-mob-expanded-border;
										}

										a.active {
											// background-color: $nav-mob-4rd-bg-color;
											// color: $action-hover;
										}
									}
								}
							}
						}
					}
					
					/* ARROWS + HIGHLIGHT */ 
					ul.menu {	
						// box-shadow: none;
						
						li {
							//border-top:$nav-mob-expanded-border;
							
							a {
								.sub-arrow {
									// background:$arrow-grey-down no-repeat scroll left center transparent;
									// background-size:70%;
									// right:0;
									// height: 39px;
									// margin-top: -19px;
									//border-left: $nav-mob-border;
								}	
															
								&.active {
									.sub-arrow {
										// background:$arrow-white-down no-repeat scroll left center transparent;
										// background-size:70%;
										// top:23px;
									}
								}

								&:hover, 
								&.active:hover  {
									.sub-arrow {
										// background:$arrow-white-down no-repeat scroll left center transparent;
										// background-size:70%;
										// top:23px;
									}								
								}
							}
							
							&.expanded {								
								ul.menu a.current {
									// background-color: $nav-active-bg-color;

									&.active {
										// background-color:$nav-active-bg-color;
										// color:$nav-hover-text-color;
										.sub-arrow {
											// background:$arrow-white-down no-repeat scroll left center transparent;
											// background-size:70%;
											// top:23px;
										}
									}

									&:hover {
										// background-color:$nav-sub-hover-bg-color;
										// color:$nav-hover-text-color;

										.sub-arrow {
											// background:$arrow-white-down no-repeat scroll left center transparent;
											// background-size:70%;
											// top:23px;
										}
									}
								}
								
								////////////**     2nd LEVEL     **////////////
								& > a.highlighted { 
									// background-color:$nav-expanded-text-color;
									// color:$white;
									// border-top:$nav-mob-expanded-border;
									// border-bottom:$nav-mob-expanded-border;

									.sub-arrow {
										// background:$arrow-white-up no-repeat scroll left center transparent;
										// background-size:70%;
										// top: 23px;
									}

									&:hover, 
									&.current.active {
										// background-color:$nav-sub-hover-bg-color;
										// color:$nav-hover-text-color;

										.sub-arrow {
											// background:$arrow-white-up no-repeat scroll left center transparent;
											// background-size:70%;
											// top:23px;
										}
									}
								}

								////////////**     3rd LEVEL     **////////////
								& > ul.menu > li.expanded > a.highlighted { 
									// background-color: $nav-mob-3rd-highlight-bg-color;
									// color: $nav-mob-3rd-highlight-text-color;
									//border-top:$nav-mob-expanded-border;

									.sub-arrow {
										// background:$arrow-grey-up no-repeat scroll left center transparent;
										// background-size:70%;
									}

									&:hover, 
									&.current.active {
										// background-color:$nav-sub-hover-bg-color;
										// color:$nav-hover-text-color;

										.sub-arrow {
											// background:$arrow-red-up no-repeat scroll left center transparent;
											// background-size:70%;
										}
									}								
								}

								////////////**     4th LEVEL     **////////////
								& > ul.menu > li.expanded > ul.menu > li.expanded > a.highlighted { 
									// background-color: $nav-mob-4rd-highlight-bg-color;
									// color: $nav-mob-4rd-highlight-text-color;
									//border-top:$nav-mob-expanded-border;

									.sub-arrow {
										// background:$arrow-grey-up no-repeat scroll left center transparent;
										// background-size:70%;
									}

									&:hover, 
									&.current.active {
										// background-color:$nav-sub-hover-bg-color;
										// color:$nav-hover-text-color;

										.sub-arrow {
											// background:$arrow-white-up no-repeat scroll left center transparent;
											// background-size:70%;
										}
									}								
								}
							}
						}
					}
				}
			}
		}
	}
}
// TOP MENU SPECIFIC STYLES
.menu-mlid-3251 {
	border-top:$nav-mob-border;
	border-bottom:$nav-mob-expanded-border;
}
.menu-mlid-3253 {
    border-top: $nav-mob-expanded-border;
    border-bottom: $nav-mob-border;
}
// .menu-mlid-3318 {
// 	border-top:$nav-mob-expanded-border;
// 	border-bottom:$nav-mob-border;
// }
// // Directory menu mobile fix
// .directory-menu #main-menu-state:not(:checked) ~ ul.menu {
//     display: block;
// }

// // Hide menu position rule menu entries
// .leaf_node .menu-block-1.menu-name-main-menu .leaf.active-trail.active {
// 	display: none;
// }
