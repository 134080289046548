.i18n-en .site-title a { background:url(../logo.png) no-repeat scroll 0 0 transparent; }
// .i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
// .i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }

#site-header {

	// background-color:$header-bg-color;
	@media (max-width:720px) and (max-width:879px) {
	// @media (max-width:720px) and (max-width:$nav-desktop-trigger-width - 1px) {
		border-bottom: none;
	}
	@media (max-width:719px) {
		margin-bottom: 30px;
	}
	// &:after {
	// 	content: " ";
	// 	display: block;
	// 	height: 0;
	// 	clear: both;
	// }
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	// .user-menu {
		// float: right;
  //   	margin-top: -40px;
		// margin-right:20px;
		// @media (max-width:719px) {
		// 	position:absolute;
		// 	right:0;
		// }
	// 	ul {
	// 		list-style-type:none;
	// 		li {
	// 			display:inline-block;
	// 			margin-left:20px;
	// 			a {
	// 				color:$primary;
	// 				font-weight:bold;
	// 				&:hover {
	// 					color:$sunset_orange;
	// 				}
	// 				.active {
	// 					color:$true_blue;
	// 				}
	// 			}
	// 			a.active {
	// 				color:$true_blue;
	// 			}
	// 		}
	// 	}
	// }
	.block-menu.header-links {
		margin-top: 5em;
		font-size: 0.750rem;
		// clear: right;
  //   margin-top: 0;
  //   margin-bottom: 20px;
		// @media (max-width:719px) {
		// 	display:none;
		// }
		// //@media (max-width:879px) {
		@media (max-width:969px) {
			// position:absolute;
			// top:-35px;
			display: none;
		}
		a {
			padding: 4px 10px;
		// 	border: solid 1px $gainsboro;
		// 	color:$true_blue;
		// 	font-size:1.125em;
		// 	font-weight:bold;
		// 	padding:8px 20px;
		// 	&:hover {
		// 		color:$sunset_orange;
		// 		border: solid 1px $sunset_orange;
		// 	}
		// 	&.active {
		// 		color:white;
		// 		background:$true_blue;
		// 		&:hover {
		// 			border: solid 1px white;
		// 		}
		// 	}
		}
		@include media(1130px) {
			margin-top: 3em;
			font-size: 1.125rem;
			a {
				padding: 8px 20px;
			}
		}
	}
	/*.header-links {
		clear: right;
    	margin-top: 0;
    	margin-bottom: 20px;
		@media (max-width:719px) {
			display:none;
		}
		@media (max-width:879px) {
			position:absolute;
			top:-35px;
		}
		a { 
			&.active {
				color:white;
				background:$true_blue;
			}
		}
	}*/
	// .header-inner-wrapper,
	// #main-navigation {
	// 	position:relative;
	// 	@include outer-container($site-max-width);
	// }
	.site-branding {
		margin-top:0;
		margin-left:0;
		// position: relative;
  //   //@include media(880px) {
  //   @include media(960px) {
		// 	top:0;
		// }
		.site-title {
			//position:absolute;
			//left:0;
			//top:0;//2.5rem;
			// float: left;
			margin:0.67em 0;
			margin-left:1.25rem;
			margin-bottom: 1em;
			
			a {
				// background-size:contain;
				// display:block;
				// text-indent:-999em;
				// overflow:hidden;
				width:300px;
				height:105px;
				// margin-bottom: 1em;
				@media (max-width:360px) {
					width:170px;
					margin-top:20px;
					margin-bottom:-45px;
					margin-left:-10px;
				}
				@media (min-width:361px) and (max-width:719px) {
					width:230px;
					margin-top:0;
					margin-bottom:-25px;
					height:85px;
				}
				//@media (min-width:720px) and (max-width:879px) {
				@media (min-width:720px) and (max-width:959px) {
					margin-top:0;
					margin-bottom:-20px;
				}
			}
		}
		// .site-slogan {
		// 	position:absolute;
		// 	left:235px;
		// 	top:2.5rem;
		// }
		.view-id-site_header {
			float: left;
			font-family: $title-font-family;
			margin: 0;
			padding-left: 18px;
			width: 100%;
			background-color: $sapphire_blue; 
			margin-bottom: -30px;
			height: 30px;
			.card-location,
			.card-display-date {
				color: white;
				font-size: 0.750rem;
				line-height: 1.125rem;
				margin-bottom: 0;
				padding: 0;
				display: inline-block;
			}
			@include media(720px) {
				display: inline-block;
				width: auto;
				background-color: transparent;
				margin-top: 3em;
				margin-left: 20px;
				padding-left: 20px;
				border-left: 2px solid #D8D8D8;
				height: auto;
				.view-content {
					display: table;
					height: 64px;
					.views-row {
						display: table-cell;
						vertical-align: middle;
						.card-location,
						.card-display-date {
							display: block;
							color: $deep_koamaru;
							font-size: 1rem;
							line-height: 1.375rem;
						}
					}
				}
			}
		}
	}
//	/*.block-search {
//		float:right;
//		height:10rem;
//		@include media(880px) {
//			height:auto;
//		}
//		h2 {
//			font-family:$base-font-family;
//			font-size:1rem;
//			color:$primary;
//			float:left;
//			line-height:1.5;
//			margin-top:1rem;
//			margin-right:0.75rem;
//			cursor:pointer;
//			display:none;
//			@include media(880px) {
//				margin-right:2.5rem;
//			}
//		}
//		.form-wrapper {
//			display:none;
//			@include media(880px) {
//				display:block;
//			}
//		}
//		.form-item-search-block-form {
//			margin-top:0.75rem;
//			margin-right:0.75rem;
//			margin-bottom:0;
//			@include media(880px) {
//				margin-bottom:3rem;
//			}
//			@include media($wide) {
//				margin-bottom:3rem;
//			}
//			float:left;
//			input.form-text {
//				width:10rem;
//				height:1.875rem;
//				background-color:$header-search-field-bg-color;
//				color:$header-search-field-text-color;
//				box-shadow:$header-search-field-box-shadow;
//				border:$header-search-field-border;
//				border-radius:$header-search-field-border-radius;
//				font:$header-search-field-font;
//				padding:0.375rem 0.75rem;
//			}
//		}
//		.form-actions {
//			float:left;
//			margin-top:0.75rem;
//			@include media(880px) {
//				margin-bottom:5rem;
//			}
//			input.form-submit {
//				background: transparent $search no-repeat scroll 0 0;
//				background-size:14px;
//				width:0.875rem;
//				height:0.875rem;
//				text-indent:-999em;
//				overflow:hidden;
//				padding:0;
//				position:absolute;
//				right:1.25rem;
//				top:1.25rem;
//			}
//		}
//	}*/
	
	.search-toggle-wrapper {
  //   position: absolute;
  //   top: -60px;
		// right: 58px;
		// //display:none;
		
		// @include media($narrow) {
		// 	top: -90px;
  //   	right: 80px;
		// }

  //   //@include media(880px) {
  //   @include media(960px) {
		// 	top: 0;
  //   	right: 0;
		// }

		//@include media(880px) {
			// display: block;
			
			.search-toggle {
				// height: 90px;
				// width: 70px;
				// text-indent: -9999px;
				background: transparent $search-icon-grey no-repeat scroll 50% 50%;
				// background-size: 35px !important;

				//@media (min-width:$narrow) and (max-width:$normal) {
				//	height: 85px;
				//	top: -39px; 
				//}
				// @include media($mobile) {
					// background-size: 30px !important;
					// background: transparent $search-icon no-repeat scroll 50% 50%;
					// height: 60px;
    	// 			width: 60px;
					//margin-top:20px;
					//margin-right:-8px;
				// }
				
				// @include media($narrow) {
					// background-size: 44px !important;
					// background: transparent $search-icon no-repeat scroll 50% 50%;
					// height: 80px;
    	// 			width: 80px;
					// margin-top:10px;
				// }
				
    //@include media(880px) {
    @include media(960px) {
					// background-size: 22px !important;
					// background: transparent $search-icon-white no-repeat scroll 50% 50%;
					// height: 35px;
					// width: 60px;
					// margin-top:0;
				}

				// @media (min-width:1151px) {
				// 	height: 43px;
				// }
				// &:hover {
				// 	background: $nav-hover-bg-color $search-icon-white no-repeat scroll 50% 50%;
				// 	cursor: pointer;
				// }

				&.active {
				// 	background-size: 28px !important;
					background: $nav-highlight-bg-color $close-icon-white no-repeat scroll 50% 50%;
				// 	@media (min-width:361px) and (max-width:889px) {
				// 		background-size: 34px !important;
					}

				// 	&:hover {
				// 		background-size: 28px !important;
				// 		background: $nav-hover-bg-color $close-icon-white no-repeat scroll 50% 50%;
				// 		@media (min-width:361px) and (max-width:889px) {
				// 			background-size: 34px !important;
				// 		}
				// 	}
				// }
			}
		//}
	}
	
	.block-search {
	// 	display:none;
		
		
	// 	//@include media(880px) {
	// 		display: block;
	// 		right: 0;
	// 	//}
		
 //    //@include media(880px) {
 //    @include media(960px) {
	// 		position: absolute;
	// 	}
		
		#search-block-form {
	// 		display:none;
			
	// 		//@include media(880px) {
	// 			position: absolute;
	// 			right: 0;
				top: 0;
				background: $header-search-bg-color;
				z-index: 2;
	// 			padding: 10px;
	// 		  max-width: 100%;
	// 		box-shadow: $box-shadow;
				
 //    //@include media(880px) {
 //    @include media(960px) {
	// 			//top: 45px;				
	// 	    max-width: none;
	// 		}
	// 		//}
			@include media(960px) {
				top: 43px;
			}
	// 		h2 {
	// 			display:none;
	// 		}
	// 		.form-item-search-block-form {
	// 			margin: 0;
				
	// 			//@include media(880px) {
	// 			@include media(960px) {
	// 				float:left;
	// 			}
				
	// 			input.form-text {
	// 				width:26rem;
	// 				height:2.5rem;
	// 				background-color:$header-search-field-bg-color;
	// 				color:$header-search-field-text-color;
	// 				box-shadow:$header-search-field-box-shadow;
	// 				border:$header-search-field-border;
	// 				border-radius:$header-search-field-border-radius;
	// 				font:$header-search-field-font; 
	// 				padding:0.375rem 0.75rem;
	// 				margin: 0;
	// 				font-size: 1.125rem;
										
 //   				max-width: 100%;
	// 			}
	// 		}
	// 		.form-actions {
	// 			float:left;
	// 			margin:0;
	// 			input.form-submit {
	// 				background: transparent $search-icon no-repeat scroll 0 0;
	// 				background-size:24px;
	// 				width:24px;
	// 				height:24px;
	// 				text-indent:-999em;
	// 				overflow:hidden;
	// 				padding:0;
	// 				position:absolute;
	// 				right:1.25rem;
	// 				top:1.2rem;
					
	// 				&:hover {
	// 					background: transparent $search-icon-hover no-repeat scroll 0 0;
	// 					cursor: pointer;
	// 				}
	// 			}
	// 		}
		}
	}
	// .block-menu {
	// 	float:right;
	// 	//margin-top:1rem;
	// 	margin-right:0.5rem;
	// 	z-index: 5;
		
 //    //@include media(880px) {
 //    @include media(960px) {
	// 		//margin-right:1.5rem;
	// 		//margin-bottom:5rem;
	// 	}
	// 	li {
	// 		display:inline-block;
	// 		margin:0 0.75rem 0 0;
	// 	}
		
	// 	a {
	// 		font:$header-menu-font;
	// 		color:$header-menu-link-color;
			
	// 		&:hover {
	// 			color:$header-menu-link-hover;
	// 		}
	// 	}
	// }
	// .block-locale {
	// 	clear:both;
	// 	display:none;
	// }
}

// .logged-in #site-header .user-menu ul {
// 	li.menu-mlid-3310,
// 	li.menu-mlid-3311 {
// 		display:none;
// 	}
// }

#main-navigation {
	@media (min-width:970px) {
		li.menu-mlid-3251,
		li.menu-mlid-3252,,
		li.menu-mlid-3253 {
			display:none;
		}
	}
}
