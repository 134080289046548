.front .section-row.section-full-width-image {
  border-bottom: 4px solid $sapphire_blue;
  .card-full-width-image {
    height: 487px;
    @include media($normal) {
      height: 640px;
    }
    &:before {
      background: $pattern-dark-6, linear-gradient(0deg, rgba(7,118,223,0.8) 0%, rgba(255,235,0,0.96) 100%),;
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity:1;
    }
    .full-width-box {
      font-family: $title-font-family;
      display: inline-block;
      background: transparent;
      color:$deep_koamaru;
      position:absolute;
      padding: 20px 10px;
      left: calc(50% - 580px);
      width: 1160px;
      top: 10px;
      margin: 0 auto;
      z-index: 2;
      @include media($narrow) {
        padding: 30px 20px;
      }
      @media (max-width:939px){
        left: calc(50% - 210px);
        width: 420px;
      }
      @media (min-width:940px) and (max-width:1300px) {
        left: calc(50% - 470px);
        width: 940px;
      }
      @media (max-width:540px){
        position: relative;
        width: 100%;
        left:0px;
      }
      
      h1 {
        margin-top: 0;
        margin-bottom:5px;
        font-weight: bold;
        font-size: 1.625rem;
        line-height: 2rem;
        letter-spacing: -2px;
        @include media($normal) {
          margin-top: 1.25em;
          font-size: 3.125rem;
          line-height: 3.625rem;
        }
      }
      p {
        margin:0;
        font-size:1.250rem;
        line-height: 1.5rem;
        &.larger-text {
          font-size: 1.5rem;
          line-height: 1.750rem;
          letter-spacing: -2px;
          @include media($normal) {
            font-size: 2.5rem;
            line-height: 2.875rem;
          }
        }
        @include media($normal) {
          font-size:1.875rem;
          line-height: 2.188rem;
        }
      }
      .cd-wrapper {
        .cd-inner {
          display: inline-block;
          justify-content: center;
          .cd-block {
            display: inline-block;
            margin: 22px 10px 10px;
            @include media($narrow) {
              margin: 32px 20px 20px;
            }
            .cd-number {
              font-size: 2.5rem;
              line-height: 2.875rem;
              padding: 27px 37px;
              background: white $bg-pattern-dark-6 50% 50% / 85px auto;
              margin-bottom: 4px;
            }
            .cd-title {
              background: $deep_koamaru;
              color: white;
              text-transform: uppercase;
              font-size:1.250rem;
              line-height:1.875rem;
              padding: 10px;
            }
          }
          .red-button {
            margin: 0 10px;
            font-size: 1.125rem;
            @include media($narrow) {
              margin: 0 20px;
            }
            &:hover {
              background: $sapphire_blue;
            }
          }
        }
      }
    }
    img {
      width: auto;
      position: absolute;
      top: 0;
      max-width: none;
      height: 100% !important;
      z-index: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
body:not(.front) .section-row.section-full-width-image {
  .card-full-width-image {
    .full-width-box {
      background: white;
    }
  }
}
