// // /* CARD GENERAL */
@mixin card-hover() {
  background-color:$card-hover-bg-color;
  box-shadow:$card-hover-shadow;
//   border: none;
  
  .card-text {
    border-top:$card-hover-border;
  }

// /*  * {
//     color:$card-text-hover-color;   
//   }*/

//   .card-header,
//   .card-header *,
//   .card-text,
//   .card-text * {
//     color:$card-text-hover-color;
//     h1, h2, h3, h4, h5, h6,
//     .card-title,
//     .card-title-et,
//     .card-title-field {
//       color: $card-action-hover;
//     }
//   }

//   .card-display-date {
//     background:$card-date-hover-bg-color;
//     color: $card-date-hover-text-color;
//   }
}


// // /* CARD BASE COLOUR OPTIONS */



@mixin card-light() {
  background-color: $card-light-bg-color;
//   border-color: $card-light-border-color;

//   h1, h2, h3, h4, h5, h6,
//   .card-title,
//   .card-title-et,
//   .card-title-field {
//     color: $card-light-title-color;
//   }
//   .card-text {
//     color:$card-light-text-color;

//     a {
//       color:$card-light-action-default;
//       h1, h2, h3, h4, h5, h6,
//       .card-title,
//       .card-title-et,
//       .card-title-field {
//         color:$card-light-title-color;
//       }
//       &:hover {
//         color:$card-light-action-hover;
//         h1, h2, h3, h4, h5, h6,
//         .card-title,
//         .card-title-et,
//         .card-title-field {
//           color:$card-light-action-hover;
//         }
//       }
//     }
  }

//   .card-title /* card line-through */ {
//     h2 span {
//       background-color:$card-light-bg-color;
//     }
//   }

//   .card-subtitle {
//     color: $card-light-subtitle-color;
//   }
// }

// @mixin card-medium() {
//   background-color: $card-medium-bg-color;
//   border-color: $card-medium-border-color;

//   h1, h2, h3, h4, h5, h6,
//   .card-title,
//   .card-title-et,
//   .card-title-field {
//     color: $card-medium-title-color;
//   }
//   .card-text {
//     color:$card-medium-text-color;

//     a {
//       color:$card-medium-action-default;
//       h1, h2, h3, h4, h5, h6,
//       .card-title,
//       .card-title-et,
//       .card-title-field {
//         color:$card-medium-title-color;
//       }
//       &:hover {
//         color:$card-medium-action-hover;
//         h1, h2, h3, h4, h5, h6,
//         .card-title,
//         .card-title-et,
//         .card-title-field {
//           color:$card-medium-action-hover;
//         }
//       }
//     }
//   }

//   .card-title /* card line-through */ {
//     h2 span {
//       background-color:$card-medium-bg-color;
//     }
//   }

//   .card-subtitle {
//     color: $card-medium-subtitle-color;
//   }
// }

// @mixin card-dark() {
//   background-color: $card-dark-bg-color;
//   border-color: $card-dark-border-color;

//   h1, h2, h3, h4, h5, h6,
//   .card-title,
//   .card-title-et,
//   .card-title-field {
//     color: $card-dark-title-color;
//   }
//   .card-text {
//     color:$card-dark-text-color;

//     a {
//       color:$card-dark-action-default;
//       h1, h2, h3, h4, h5, h6,
//       .card-title,
//       .card-title-et,
//       .card-title-field {
//         color:$card-dark-title-color;
//       }
//       &:hover {
//         color:$card-dark-action-hover;
//         h1, h2, h3, h4, h5, h6,
//         .card-title,
//         .card-title-et,
//         .card-title-field {
//           color:$card-dark-action-hover;
//         }
//       }
//     }
//   }

//   .card-title /* card line-through */ {
//     h2 span {
//       background-color:$card-dark-bg-color;
//     }
//   }

//   .card-subtitle {
//     color: $card-dark-subtitle-color;
//   }
// }

// @mixin card-highlight() {
//   background-color: $card-highlight-bg-color;
//   border-color: $card-highlight-border-color;

//   h1, h2, h3, h4, h5, h6,
//   .card-title,
//   .card-title-et,
//   .card-title-field {
//     color: $card-highlight-title-color;
//   }
//   .card-text {
//     color:$card-highlight-text-color;

//     a {
//       color:$card-highlight-action-default;
//       h1, h2, h3, h4, h5, h6,
//       .card-title,
//       .card-title-et,
//       .card-title-field {
//         color:$card-highlight-title-color;
//       }
//       &:hover {
//         color:$card-highlight-action-hover;
//         h1, h2, h3, h4, h5, h6,
//         .card-title,
//         .card-title-et,
//         .card-title-field {
//           color:$card-highlight-action-hover;
//         }
//       }
//     }
//   }

//   .card-title /* card line-through */ {
//     h2 span {
//       background-color:$card-highlight-bg-color;
//     }
//   }

//   .card-subtitle {
//     color: $card-highlight-subtitle-color;
//   }
// }
// // /* CARD MORE LINKS */
// @mixin card-more-link-default() {
//   a.card {
//     background:$card-more-link-default-bg;
//     color: $card-more-link-default-color;
//     &:after {
//       background: $card-more-link-default-icon;
//     }
//     &:hover {
//       background:$card-more-link-default-hover-bg;
//       color: $card-more-link-default-hover-color;
//       &:after {
//         background: $card-more-link-default-hover-icon;
//       }
//     }
//   }
// }

// @mixin card-more-link-alt() {
//   a.card {
//     background:$card-more-link-alt-bg;
//     color: $card-more-link-alt-color;
//     &:after {
//       background: $card-more-link-alt-icon;
//     }
//     &:hover {
//       background:$card-more-link-alt-hover-bg;
//       color: $card-more-link-alt-hover-color;
//       &:after {
//         background: $card-more-link-alt-hover-icon;
//       }
//     }
//   }
// }


// // /* CARD FEED MORE LINKS */
// @mixin card-feed-link-default() {
//   a {
//     background:$card-feed-link-default-bg;
//     color: $card-feed-link-default-color;
//     &:after {
//       background: $card-feed-link-default-icon;
//     }
//     &:hover {
//       background:$card-feed-link-default-hover-bg;
//       color: $card-feed-link-default-hover-color;
//       &:after {
//         background: $card-feed-link-default-hover-icon;
//       }
//     }
//   }
// }


// @mixin card-feed-link-alt() {
//   a {
//     background:$card-feed-link-alt-bg;
//     color: $card-feed-link-alt-color;
//     &:after {
//       background: $card-feed-link-alt-icon;
//     }
//     &:hover {
//       background:$card-feed-link-alt-hover-bg;
//       color: $card-feed-link-alt-hover-color;
//       &:after {
//         background: $card-feed-link-alt-hover-icon;
//       }
//     }
//   }
// }
