// // /* DEFAULT CARD LAYOUT */
.section-row {
  .card {
    box-shadow:$card-shadow;
    &:hover {
      box-shadow:$card-shadow !important;
      .card-text .card-title-field {
        color: $sapphire_blue;
      }
      .card-summary-wrapper .card-view-more {
        background: $sunset_orange $arrow-white-right calc(100% - 20px) 50% / 10px 20px no-repeat;
      }
    }
    .card-image,
    .card-photo,
    .card-logo {
      width: 90%;
      margin-right: 5%;
      float: right;
      box-shadow:0 0 4px 0 rgba(0,0,0,0.1);
      &+ .card-text {
        margin-top: -60px;
        width: auto;
      }
    }
    .card-text {
      background: white $bg-pattern-dark-6 50% 50% / 85px auto;
      margin-top: 0;
      margin-left: 3%;
      margin-right: 3%;
      display: inline-block;
      position: relative;
      border-top: none;
      box-shadow:0 0 4px 0 rgba(0,0,0,0.1);
      width: 100%;
      .card-title-field {
        font-size: 1.625rem;
      }
      &.card-with-summary {
        width: auto;
      }
    }
    .card-summary-wrapper {
      color: $primary;
      background: white;
      padding: 40px 20px 20px;
      margin-top: -40px;
      box-shadow:0 0 4px 0 rgba(0,0,0,0.1);
      .card-summary + .card-view-more {
        margin-top: 1em;
      }
      .card-view-more {
        color: white;
        margin-top:2em;
        border-radius: $button-border-radius;
        background: $button-bg $arrow-white-right calc(100% - 20px) 50% / 10px 20px no-repeat;
        display: inline-block;
        padding: 10px 40px 10px 20px;
        font-size: 1.125rem;
        font-family: $title-font-family;
      }
    }
  }
  &.row-max-2 {
    @include media($normal) { 
      .card {
        .card-image,
        .card-photo,
        .card-logo {
          width:calc(100%/12 * 4);
          float: left;
          margin-top: 20px;
        }
        .card-text {
          margin-top: 0;
        }
        .card-summary-wrapper {
          padding: 20px 20px 20px 50px;
          margin-top: -30px;
          margin-left: calc((100%/12 *4) - 20px);
        }
      }
    }
  }
  &.row-max-1 {
    article {
      .card {
        .card-image,
        .card-photo,
        .card-logo {
          width: 90%;
          margin-right: 5%;
          float: right;
          img {
            width: 100%;
          }
          &+ .card-text {
            margin-top: 20px;
          }

        }
      }
    }
    @include media(740px) {
      article {
        .card {
          width: calc(100%/12 *10);
          margin-left: auto;
          margin-right: auto !important;
          .card-image,
          .card-photo,
          .card-logo {
            width: calc(100%/12 * 4);
            margin-right: 0;
            float: left;
          }
        }
        .card-text {
          margin-top: 20px;
        }
        .card-summary-wrapper {
          margin-left: calc((100%/12 * 4) - 20px);
          padding: 40px 20px 20px 40px;
        }
      }
    }
  }
}

.card .card-room {
	font-size:0.875em;
	line-height:1.57142857142857;
	margin-top:10px;
}

// Override eahealth card styles
.section-row:not(.row-max-1) {
  .view-id-links.eahrc-links.view-display-id-block {
    .card {
      .card-image + .card-text {
        margin-top: -60px;
        height: auto;
        background: white;
        border: 0;
        h3 .card-title-field {
          color: $sapphire_blue;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.row-max-2 {
    .view-id-links.eahrc-links.view-display-id-block {
      .card {
        .card-image + .card-text {
          margin: 0 3% 10px;
        }
      }
    }
  }
}

// .card {
//   border:none;
//   border-radius: $card-border-radius;
//   box-shadow:$card-shadow;
//   margin-bottom:$card-margin-bottom;
  
//   transition: $card-transition;
  

//   //border-top: $septenary-border !important;
  
//   //  DIRECTORY CARDS
//   &.sponsored-card {    
//     .member-level {
//       max-width: 88px;
//       text-align: center;
//       margin-right: 30px;
//     }
//   }
  
//   &.card-Gold,
//   &.card-Gold-EAHRC { 
//     .card-text {
//       border-top: $gold-border;
//     }
//     .icon-Gold:after {
//       margin-top:-33px;
//     }
//   }
  
//   &.card-Silver {
//     .card-text {
//       border-top: $silver-border;
//     }
//     .icon-Silver:after {
//       margin-top:-33px;
//     }
//   }
  
//   &.card-Bronze {
//     .card-text {
//       border-top: $bronze-border;
//     }
//     .icon-Bronze:after {
//       margin-top:-33px;
//     }
//   }

//   &.slick-slide {
//     margin-top: 10px;
//   }
//   .card-title,
//   .card-title-et,
//   .card-title-field {
//     font-family:$card-title-font-family;
//   }

//   h3 {
//     display:block;
//     font:$card-title-font;
    
//     .card-title-field,
//     .card-title-et,
//     .card-title {
//       display:block;
//       font:$card-title-font;
//     }
//   } 
//   .card-text {
//     padding: $card-text-padding;
//     border-top: $card-border;
//     a { word-wrap: break-word; }
//   }

//   .card-authors,
//   .card-abbr-authors,
//   .card-publisher {
//     margin-top:10px;
//   }
//   .view-display-id-countries,
//   .view-display-id-health {
//     display: inline-block;
//     // width: 100%;
//   }
// }

// .card-address-country {
//   display:inline;
//   &> div {
//   display:inline;
//   }
  
//   .opportunity-street:after {
//     content:",";
//     margin-left:1px;
//   }
//   .opportunity-street + .opportunity-country {
//     margin-left:3px;
//   }
// }


// .card-display-date {
//   background: $card-date-bg-color;
//   border-bottom:$card-date-border;
//   border-radius:$card-date-border-radius;
//   color:$card-date-text-color;
//   font:$card-date-font;
//   padding:15px 0;
// }

// .card-date {
//   margin-top:10px;
//   // float: left;
//   // margin-right: 1em;
// }
// .card-date,
// .date-display-single {
//   //text-transform:lowercase;
//   color:$nickel;
//   font-size:1em;
// }

// // /* MORE LINK */
// .section-row:not(.view-type-slider) //, .side-row:not(.view-type-slider) /* to target sidebar more links separately */ 
//   {
//   .card-more-link {
//     display:table !important;
//     margin-left:2.35765%;
//     text-align:center;

//     @media (min-width:0) and (max-width:540px) {
//       margin-left:0;
//       width:100%;
//     }

//     a.card {
//       box-sizing:border-box;
//       display:table-cell;
//       font:$card-more-link-font;
//       padding:$vert-space $horz-space;
//       vertical-align:middle;
//       width:100%;

//       @media (min-width:0) and (max-width:719px) {
//         display: block;
//       }

//       &:after {
//         content:" ";
//         display:inline-block;
//         height:16px;
//         margin:0 0 -2px 10px;
//         width:14px;
//       }
//     }
//   }

//   &.row-max-1 {
//     .card-more-link,
//     .card-last-link-url {
//       margin-left: 0;
//       float: right;
//     }
//   }
// }

// // /* FEED LINK */
// .card-feed-link {
//   clear: both;
//   display: block;
//   float: right;
//   font:$card-feed-link-font;
//   overflow: hidden;
//   padding:10px 20px;
//   margin-right:-30px;
//   a {
//     padding:10px 20px;
//     &:after {
//       content: "";
//       display: inline-block;
//       width: 18px;
//       height: 20px;
//       //border-radius: 50%;
//       margin-left: 10px;
//       vertical-align: sub;
//       margin-right:15px;
//     }
//   }
// }



// // /* INNER CARD ELEMENTS */
// .tweet .card-text {
//   display:block;
//   font:normal 1.125em/1.22222222222222 $base-font-family;
// }

// .card-logo {
//   background:#FFFFFF;
// }
// .card-video {
//   margin-bottom:15px;
  
//   span.ext {
//     display:none;
//   }
// }

// .card-tags {
//   display: inline-block;
//   font-size: 0.875em;
//   font-weight: 600;

//   ul {
//     display: inline-block;
//     li {
//       float: left;
//       margin-right: 10px;
//       &:after {
//         content:"|";
//         display: inline-block;
//         float: right;
//         margin-left: 10px;
//       }
//       &:last-child {
//         &:after {
//           content:"";
//         }
//       }         
//     }
//   }
// }

// .card-start-date {
//   margin-right:2px;
//   padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
//   text-align:center;
  
//   .card-date-day {
//     font-size:1.75em;
//     line-height:0.78571428571429;
//   }
//   .card-date-month {
//     font-size:0.875em;
//     line-height:1.22222222222222;
//     text-transform:uppercase;
//   }
// }

// .card-citation {
//   font-style:italic;
// }

// .card-deadline {
//   margin-bottom:10px;
// }

// .card-location {
//   font-size:0.875em;
//   line-height:1.57142857142857;
//   margin-bottom: 1em;
// }
// .card-type {
//   font-size:0.875rem;
//   line-height:1.57142857142857;
// }
// .card-label {
//   font-weight:bold;
// }
// .card-start-date {
//   margin-right:2px;
//   padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
//   text-align:center;
  
//   .card-date-day {
//     font-size:1.75em;
//     line-height:0.78571428571429;
//   }
//   .card-date-month {
//     font-size:0.875em;
//     line-height:1.22222222222222;
//     text-transform:uppercase;
//   }
// }
// .card-image-et,
// .card-cover-image {
//     margin-bottom: -7px;
// }

// /*.card-date-display-override {
//   font-size: 0.875em;
// }*/
// .card-resource-type,
// .card-content-type {
//   display:inline-block;
//   font-weight: bold;
//   font-size: 1em;
//   color:$nickel;
//   margin-bottom:15px;
//   text-transform:lowercase;
// }
// .card-journal {
//   display: inline-block;
//   font-weight: 400;
//   font-size: 0.875em;
//   /*&:after {
//     content:"|";
//     margin: 0 0px 0 2px;  
//   }*/
// }
// .card-email {
//   font-weight: 600;
// }

// .card .card-room {
//   font-size:0.875em;
//   line-height:1.57142857142857;
//   margin-top:10px;
// }

// .directory-job-title {
.card-job-title {
  font-size:1rem;
  line-height:1.375;
  margin-bottom:15px;
  font-weight: 600;
  font-family: $title-font-family;
}

// .card-news-type,
// .card-event-type {
//   text-transform:lowercase;
//   font-weight:bold;
//   color:$nickel;
//   margin-bottom: 10px;
// }

// .card-event-coverage {
//   color: $green;
//   background: $icon-update no-repeat scroll 0 50% / 17px 17px;
//   padding-left: 24px;
//   margin-bottom: 15px;
// }
// main {
//   .section-row,
//   .side-row {
//     a.card:hover .card-text .card-event-coverage {
//       color: $green;
//     }
//   }
// }

// .card-footer {
//   display: block;
//   line-height: 1.125em;
// }

// .card-summary {
//   margin-top:10px;
// }

// @media (min-width:$narrow) and (max-width:959px) {
//   .card-with-summary {
//     .card-summary {
//       font-size: 0.875rem;
//       line-height: 1.42857142857143;
//     }
//   }
// }
// //Display box-link card-summary on hover 
//  //@include card-hidden-summary;


// // DIRECTORY CARDS

// //.sponsored-card .card-header {
// //  border-top: 3px solid $sapphire_blue;
// //}

// .card-header {
//   border-bottom:1px solid $lavender_mist;
//   padding:20px;
  
//   margin: -20px -20px 20px;
  
  
//   .card-membership-level {
//     color:$davys_grey;
//     float: right;
//     font: bold 0.875em $card-title-font-family;
//     //display: inline-block;
//     //padding: 8px 0 20px 20px;
//     //border-left:1px solid grey;
//   }
  
// }
// .directory-tag {
//   float: left;
// }
// .directory-tag,
// .directory-tag-multiple li {
//   border: solid 1px $gainsboro;
//   color: $davys_grey;
//   display: inline-block;
//   font-size:0.875em;
//   margin-right: 10px !important;
//   margin-top:10px !important;
//   padding:2px 10px !important;
//   text-transform:lowercase;
//   font-weight: 600;
// }

// .card-text {  
//   .card-address-country,
//   .card-description {
//     margin-bottom: 0.5em;
//   }
  
//   .card-date-posted {
//       //float: right;
//     margin-top: 10px;
//   }
// }

// main .section-row a.card:hover .card-text {
//   .card-opportunity-type,
//   .card-organisation-type {
//     div {
//       color: $grey;
//     }
//   }
// }

// .card-Basic .card-membership-level {
//   display:none;
// }

// .section-row {
//   .card .view-id-health_country_tags .view-content .views-row {
//     width:auto;
//   }
// }

// // RESOURCE / PUBLICATION / JOURNAL CARDS

// .card .card-cover-image {
//   margin-bottom:10px;
// }
// .row-max-1,
// .row-max-2,
// .row-max-3 {
//   .card {
//     .card-cover-image {
//       float:right;
//       margin-left:10px;
//       max-width: 180px;
//       margin-bottom:0;
//     }
//   }
// }
// .row-max-2 .card .card-cover-image {
//   @media (max-width:959px) {
//     float:none;
//     max-width:100%;
//     margin-bottom:10px;
//     margin-left:0;
//   }
// }

// .row-max-3 .card .card-cover-image {
//   @media (max-width:1349px) {
//     float:none;
//     max-width:100%;
//     margin-bottom:10px;
//     margin-left:0;
//   }
// }

// .node-journal {
//   .card .card-summary-wrapper {
//     margin-top: 10px;
//     overflow:hidden;
//   }
// }

// // BOX LINKS
// .section-row:not(.row-max-1),
// .side-row {
//   .view-id-links.eahrc-links.view-display-id-block {
//     .card {
//       box-shadow: none;
//       background-color: transparent !important;
//       .card-image {
//         position: relative;
//       }
//       .card-image,
//       .card-photo {
//           &+ .card-text {
//           margin: -20px 20px 0 20px;
//         }
//       }
//       .card-text {
//         box-shadow: $box-shadow;
//         padding: 20px;
//         position: relative;
//         overflow: visible;
//         height: 100%;
//         border-top: none;
//         background: $anti_flash_white $pattern-dark-6;
//         border-left: 3px solid $middle_yellow;
//         margin: 0 20px;
//         @include media(540px) {
//           margin: 0;
//         }
//         .card-content-type {
//           padding: 0 0 10px;
//           margin-bottom: 0;
//         }
//         h3,
//         .card-summary-wrapper {
//           margin-bottom: 0;
//         }
//         h3 {
//           text-align: center;
//         }
//         .card-display-date,
//         .card-date,
//         .card-date-display-override {
//           padding-top: 10px;
//           margin-top: 0;
//         }
//         .card-event-coverage {
//           margin: 0;
//         }
//         .card-summary-wrapper {
//           padding: 10px 0;
//           margin: 0;
//           .card-summary {
//             margin-top: 0;
//           }
//         }
//         h3 .card-title-field {
//           color: $davys_grey;
//           font-size: 1.625rem;
//           line-height: 2.125rem;
//           &:after {
//             content: "";
//             display: inline-block;
//             height: 30px;
//             width: 16px;
//             margin-left: 10px;
//             background: $arrow-grey-right no-repeat scroll 50% 50%;
//             vertical-align: text-bottom;
//             background-size: 50px auto;
//           }
//         }
//         .directory-tag {
//           background-color: $anti_flash_white;
//         }
//       }
//       &:hover {
//         box-shadow: none !important;
//         .card-text {
//           border-top: none !important;
//         }
//         h3 .card-title-field {
//           color: $sunset_orange;
//           &:after {
//             background: $arrow-rose-right no-repeat scroll 50% 50%;
//             background-size: 50px auto;
//           }
//         }
//       }
//     }
//   }
// }
// // Row-max-1 specific styles
// .section-row.row-max-1 {
//   .view-id-links.eahrc-links.view-display-id-block {
//     .card {
//       background: $anti_flash_white $pattern-dark-6;
//       .card-image,
//       .card-photo {
//         @include media(540px) {
//           border-right: 3px solid $middle_yellow;
//           margin-right: -3px;
//         }
//       }
//       .card-text {
//         border-top: none;
//         @include media(540px) {
//           border-left: 3px solid $middle_yellow;
//         }
//         h3 .card-title-field {
//           color: $davys_grey;
//           font-size: 1.625rem;
//           line-height: 2.125rem;
//           &:after {
//             content: "";
//             display: inline-block;
//             height: 30px;
//             width: 16px;
//             margin-left: 10px;
//             background: $arrow-grey-right no-repeat scroll 50% 50%;
//             vertical-align: text-bottom;
//             background-size: 50px auto;
//           }
//         }
//       }
//       &:hover {
//         h3 .card-title-field {
//           &:after {
//             background: $arrow-rose-right no-repeat scroll 50% 50%;
//             background-size: 50px auto;
//           }
//         }
//       }
//     }
//     @media(max-width:540px) {
//       .card {
//         background: none;
//         box-shadow: none;
//         &:hover {
//           background: none;
//           box-shadow: none;
//         }
//       }
//       .card-text {
//         background: $anti_flash_white $pattern-dark-6;
//         border-left: 3px solid $middle_yellow;
//       }
//       .card-image,
//       .card-photo {
//         &+ .card-text {
//           background: $anti_flash_white $pattern-dark-6;
//           margin: -20px 20px 0 20px;
//           overflow: visible;
//           position: relative;
//         }
//       }
//     }
//   }
// }

// // Hide red border-top on hover
// .postscript-first,
// .content-hero {
//   .section-row.palette-default,
//   .section-row.palette-alt-1 {
//     .view-id-links.view-display-id-block a.card:hover:not(.sponsored-card) .card-text {
//       border-top: none !important;
//     }
//   }
// }

// // ALERTS

// .view-alerts .view-footer {
//   overflow:hidden;
// }

// .card-alert {
//   background-color:white;
//   padding:20px;
//   .card-type,
//   .card-type-1 {
//     font-size:1em;
//     display:inline-block;
//     margin-right:20px;
//   }
//   .card-partner-state-er,
//   .card-partner-state-er-1 {
//     display:inline-block;
//   }
//   .directory-tag {
//     margin-right:20px !important;
//   }
//   .card-title {
//     font-size: 20px;
//       margin-top: 10px;
//   }
// }

// .card-alert-date,
// .card-alert-date-1 {
//   display:inline-block;
// }

// .alert-update-icon {
//   color:$green;
//   &:before {
//     content:" ";
//     display: inline-block;
//     height:18px;
//     width:18px;
//     margin-right:6px;
//     background: $icon-alert-update no-repeat scroll 50% 50%;
//   }
// }

// .alert-alert-icon {
//   color:$sunset_orange;
//   &:before {
//     content:" ";
//     display: inline-block;
//     height:18px;
//     width:18px;
//     margin-right:6px;
//     background: $icon-alert-new no-repeat scroll 50% 50%;
//   }
// }

// a.alert.button {
//     float: right;
//   margin-bottom:40px;
//   font: $button-font;
// }

// .clear-block {
//   clear:both;
//   overflow:hidden;
// }

// .card-logo .label {
//     display: none;
// }
