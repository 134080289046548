//.table-view {
//	margin-left: 15px;
//	margin-right: 15px;
//	
//	tr {
//		width: 5%;
//	}
//}

table.views-table {
	display: table;
	width: 100%;
}

.sessions-table {
	td.card-title {
		font-size: 1.125em;
		font-weight: bold;
	}
	@media only screen and (max-width: 720px) {
	/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr,caption { 
			display: block; 
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr:first-child td { border-top: none; }
		tr:last-child td { border-bottom: none; }

		//tr { border: 1px solid #ccc; }

		td { 
			/* Behave  like a "row" */
			border: none;
			//border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 40%; 
		}

		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}

		/*
		Label the data
		*/
		td:before{ font-weight: bold; }
		//td.views-field-field-end-minute:before { content: "Time"; }
		//td.card-title:before { content: "Session"; }
		td.views-field-field-room:before { content: "Location"; }
		td.views-field-field-speaker-er:before { content: "Speaker"; }
		td.views-field-field-stream:before { content: "Stream"; }


		td.views-field-field-end-minute,
		td.card-title {
			display: inline-block;
			padding-left: 0;
			vertical-align: middle;
		}
		td.card-title {
			@include clearfix;
		}
		td.views-field-field-end-minute { width: 125px; }
	}

	@include media(720px) {
		.views-field-field-end-minute,
		.views-field-field-room,
		.views-field-field-stream {
			min-width: 145px;
			width:15%;
		}
		.card-title { width: 35%; }
		.views-field-field-speaker-er { width: 20%; }

	}
}